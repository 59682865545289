/* You can add global styles to this file, and also import other style files */
@import "../node_modules/angular-bootstrap-md/assets/scss/core/colors";
@import "../node_modules/angular-bootstrap-md/assets/scss/bootstrap/functions";
@import "../node_modules/angular-bootstrap-md/assets/scss/bootstrap/variables";
@import "../node_modules/angular-bootstrap-md/assets/scss/core/variables";
@import "../node_modules/angular-bootstrap-md/assets/scss/core/mixins";

.curvy-button {
  border-radius: 5px !important;
}

.navbar {
  background-color: transparent;
}

.full-bar-color .navbar{
  background-color: #313740;
}

.top-nav-collapse {
  background-color: #313740;
}

@media only screen and (max-width: 768px) {
  .navbar {
    background-color: #313740;
  }
}

.form-check-input {
  margin-top: -10px;
  margin-left: 0;
}

.form-check-label {
  margin-top: -25px;
  margin-left: 20px;
}
